import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"

import * as notFoundStyles from "./404.module.scss"

const Oopps = () => {
  return(
    <Layout>
        <Head title="404" description="Ohhh man!"/>
        <div className = {notFoundStyles.NotFound}>
            <h2>Ohhh man! This again</h2>
        </div>
    </Layout>
  )
}

export default Oopps
